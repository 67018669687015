(function($){
	if($("#fbooking").length > 0) {
		$("#fbooking")[0].reset();
		var test=0;
		$('body').on('click', '#cnext', function(e) {
			e.preventDefault();
			//console.log('cnext verficare '+test);
			var form = $("#fbooking");
			data = form.data("yiiActiveForm");
			$.each(data.attributes, function() {
				this.status = 3;
			});
		
			$('#fbooking').data('yiiActiveForm').submitting = false; 
			$('#fbooking').yiiActiveForm('validate');
		
			if (test == 0) {
				if (form.find('.form-control.is-valid').length !== 4 ) {
					console.log(form.find('.form-control.is-valid').length);
				} else {
					$('.field-rezervareform-data_sosire').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('.field-rezervareform-data_plecare').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});	
					$('.field-rezervareform-nume').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('.field-rezervareform-nr_adulti').css({'display':'inline-block'});
					$('.field-rezervareform-nr_copii').css({'display':'inline-block'});
					$('.field-rezervareform-tip').css({'display':'inline-block'});
					
					$('#rezervareform-observatii').removeClass('is-invalid');
					$('#rezervareform-tip').removeClass('is-invalid');
					$('#rezervareform-nr_adulti').removeClass('is-invalid');
					$('#rezervareform-nr_copii').removeClass('is-invalid');
					
					test=1;
				}
			} else if (test == 1) {
				if (form.find('.form-control.is-valid').length !== 7 ) {
					console.log("error 0");
				} else {
					$('.field-rezervareform-nr_adulti').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('.field-rezervareform-nr_copii').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('.field-rezervareform-tip').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('#cnext').css({'visibility':'hidden','position':'absolute','top': '-9999px','left': '-9999px'});
					$('.field-rezervareform-tel').css({'display':'inline-block'});
					$('.field-rezervareform-email').css({'display':'inline-block'});
					$('.field-rezervareform-observatii').css({'display':'inline-block'});
					
					$('#rezervareform-tel').removeClass('is-invalid');
					$('#rezervareform-email').removeClass('is-invalid');
					$('#rezervareform-observatii').removeClass('is-valid');
					
					$('#ctrimite').css({'display':'block'});
					test=2;
				}
			}
		});
	
		$('body').on('click', '#ctrimite', function(e) {
			//console.log('trimite');
			if(test == 2) {		
				$('#fbooking').submit();
			}
		});
	
		$('body').on('beforeSubmit', 'form#fbooking', function () {
			var form = $(this);
			if (form.find('.form-control').not('.is-valid').length) {
				return false;
			}
		// submit form
			$.ajax({
				url: form.attr('action'),
				type: 'post',
				data: form.serialize(),
				dataType: 'json',
				beforeSend: function() {
					//$('.rotate').css('display','block');
				},
				success: function (response) {
					$("#fbooking").trigger("reset");
					$('#booking_response').empty();
					$('#booking_response').append("<h3>"+response+"</h3>");
				
				},
				error:function(x,y,z) {
					console.log(x);
					console.log(y);
					console.log(z);
				},
			})
			return false;
		});
	

	
		$("#fbooking> .field-rezervareform-tip > select ").on("change", function() {
			ResetCopii();
			ResetAdulti();
			//console.log(this.value);
			if(this.value == 0) {
				AddAdulti(1);
			} else if(this.value == 1) {
				AddAdulti(2);
			} else if(this.value == 2) {
				AddAdulti(3);
			} else if(this.value == 3) {
				AddAdulti(4);
			}
			validate2();
		})
	
	
		$("#rezervareform-nr_adulti").on("change", function() {
			//console.log("tip :"+$("#rezervareform-tip").val());
			//console.log("adulti :"+this.value);
			//console.log("calc copii"+copii($("#rezervareform-tip").val(),this.value));
			AddCopii(copii($("#rezervareform-tip").val(),this.value));
			validate2();
		})
	
		$("#rezervareform-nr_copii").on("change", function() {
			if($("#rezervareform-nr_adulti").val() == "") {
				AddAdulti(adulti($("#rezervareform-tip").val(),this.value));
			}
			validate2();
		})
	}
		
		function adulti(tip,copii) {
			if (tip==0) { 
				return 1;
			} else if (tip==1 && copii==0) {
				return 2;
			} else if(tip==1 && copii==1) {
				return 2;
			} else if(tip==1 && copii==2) {
				return 1;
			}  else if (tip==2 && copii==0) {
				return 3;	
			} else if(tip==2 && copii==1) {
				return 3;
			} else if(tip==2 && copii==2) {
				return 2;
			} else if (tip==2 && copii==3) {
				return 1;
			} else if (tip==3 && copii==0) {
				return 4;	
			} else if (tip==3 && copii==1) {
			   return 4;
			} else if (tip==3 && copii==2) {
			   return 3;
			} else if (tip==3 && copii==3) {
				return 2;
			} else if(tip==3 && copii==4) {
				return 1;
			} else {
				return 0;
			}
		}
			
		function copii(tip,adulti) {
			if (tip==0) { 
				return 1;
			} else if (tip==1 && adulti == 1) {
				return 2;
			} else if(tip==1 && adulti ==2) {
				return 1;
			}  else if (tip==2 && adulti==1) {
				return 3;	
			} else if(tip==2 && adulti==2) {
				return 2;
			} else if(tip==2 && adulti==3) {
				return 1;
			} else if(tip==3 && adulti==1) {
				return 4;
			} else if (tip==3 && adulti==2) {
				return 3;
			} else if (tip==3 && adulti==3) {
				return 2;	
			} else if (tip==3 && adulti==4) {
				return 1;
			} else {
				return 0;	
			}
			
		}
	
		function ResetAdulti() {
			$("#rezervareform-nr_adulti")
				.find('option')
				.remove()
				.end();
			$("#rezervareform-nr_adulti").append('<option value="" selected="selected">Nr adulți</option>');
		}
	
		function ResetCopii() {
			$("#rezervareform-nr_copii")
				.find('option')
				.remove()
				.end();
			$("#rezervareform-nr_copii").append('<option value="" selected="selected">Nr copii (0 - 3 ani)</option>');
		}
	
		function AddAdulti(i) {
			ResetAdulti();
			for(let j=1;j<=i;j++) {
				$("#rezervareform-nr_adulti")
				.append('<option value="'+j+'">'+j+'</option>');
			}
		}
	
		function AddCopii(i) {
			ResetCopii();
			//console.log("copii i:"+i);
			for(let j=0;j<=i;j++) {
				//console.log("copii j:"+j);
				$("#rezervareform-nr_copii")
				.append('<option value="'+j+'">'+j+'</option>');
			}
		}
	
		function validate2() {
			$('#fbooking').yiiActiveForm('validate');
		}
	
	
	
})(jQuery);