import $ from 'jquery';
import {TweenMax, Power2, TimelineLite,TimelineMax} from "gsap";
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
$(document).ready(() => {
	var controller = new ScrollMagic.Controller();	
	var headerScene = null;
	var middleScene = null;
	
	function desktopmenu() {
		if (headerScene != null) {
			headerScene.destroy();
		}
		if (middleScene != null) {
			middleScene.destroy();
		}
		if($('#header').length) {
			let wrap_header=$("#wrap-header");
			let meniu=$("#wrap-header-bottom");
			let logo=$(".superfish-logo");
	
			headerScene = new ScrollMagic.Scene({
				triggerElement: '#header',
				triggerHook:0,
				offset:'0px',
				duration:'1px',
				
			})	
			.addTo(controller)
			//.addIndicators()
			;
			
			headerScene.on("end",function(event) {
				//console.log(event);
				if (event.type=="end" && event.scrollDirection=="FORWARD") {
				//	topmenu.addClass('header_fixed');
					
						TweenMax.to(
							wrap_header,
							0.6,
							
							{css:{'position':'fixed','z-index':1035,'left':0,'right':0,'top':-40}},
							0
						);
				   
			
				} else if (event.type=="end" && event.scrollDirection=="REVERSE") {
				
					
						TweenMax.to(
							wrap_header,
							0.6,
							{css:{'position':'absolute','z-index':1035,'left':0,'right':0,'top':0}},
			
							0
						); 
					
				}
			});
			
			if($('.reservation').length) {
				
			middleScene = new ScrollMagic.Scene({
				triggerElement: '.reservation',
				triggerHook:"onLeave",
				offset:'0px',
				duration:'1px',
				
			})	
			.addTo(controller)
			//.addIndicators()
			;
			middleScene.on("end",function(event) {
				//console.log(event);
			
			
			if (event.type=="end" && event.scrollDirection=="FORWARD") {
				TweenMax.to(
							meniu,
							0,
							{css:{"background-color":"rgba(255,255,255,1)"}},
							0
						);
				
			} else if (event.type=="end" && event.scrollDirection=="REVERSE") {
				 TweenMax.to(
							meniu,
							0,
							{css:{"background-color":"rgba(255,255,255,0.8)"}},
							0
						);
			
			};
			});
			}
		};
	};
	
	function mobilemenu() {
			if (headerScene != null) {
				headerScene.destroy();
			}
			if (middleScene != null) {
				middleScene.destroy();
			}
			
			let wrap_header=$("#wrap-header");
			let meniu=$("#wrap-header-bottom");
			let logo=$(".superfish-logo");
			
			headerScene = new ScrollMagic.Scene({
				triggerElement: '#header',
				triggerHook:0,
				offset:'0px',
				duration:'1px',
				
			})	
			.addTo(controller)
			//.addIndicators()
			;
			
			headerScene.on("end",function(event) {
				//console.log(event);
				if (event.type=="end" && event.scrollDirection=="FORWARD") {
				//	topmenu.addClass('header_fixed');
					
						TweenMax.to(
							wrap_header,
							0.6,
							
							{css:{'position':'fixed','z-index':1035,'left':0,'right':0,'top':-40}},
							0
						);
				   
			
				} else if (event.type=="end" && event.scrollDirection=="REVERSE") {
				
					
						TweenMax.to(
							wrap_header,
							0.6,
							{css:{'position':'absolute','z-index':1035,'left':0,'right':0,'top':0}},
			
							0
						); 
					
				}
			});
			
			if($('.reservation').length) {
			middleScene = new ScrollMagic.Scene({
				triggerElement: '.reservation',
				triggerHook:"onLeave",
				offset:'0px',
				duration:'1px',
				
			})	
			.addTo(controller)
			//.addIndicators()
			;
			middleScene.on("end",function(event) {
				//console.log(event);
			
			
			if (event.type=="end" && event.scrollDirection=="FORWARD") {
				TweenMax.to(
							meniu,
							0,
							{css:{"background-color":"rgba(255,255,255,1)"}},
							0
						);
				
			} else if (event.type=="end" && event.scrollDirection=="REVERSE") {
				 TweenMax.to(
							meniu,
							0,
							{css:{"background-color":"rgba(255,255,255,0.8)"}},
							0
						);
			
			};
			});
			
			}
			
			
			
			/*
			var headerScene = new ScrollMagic.Scene({
				triggerElement: '#mediumbar',
				triggerHook:0,
				offset:'-50px',
		//		duration:3
			})	
			.addTo(controller)
		//	.addIndicators()
			;
			headerScene.on("enter",function() {
				TweenMax.to(topmenu,.0,{css:{'position':'absolute','z-index':1035,'left':0,'right':0,'top':0}},0);
			});

			headerScene.on("leave",function() {
				TweenMax.to(topmenu,0,{css:{'position':'absolute','z-index':1035}},0);
			});
			*/
	}
	
	if(window.innerWidth >= 768) {
        desktopmenu();
	} else {
		mobilemenu();
	}
		
	$(window).resize(function() {
		if(window.innerWidth >= 768) {
			desktopmenu();
		} else {
			mobilemenu();
		}
	});
	
	//fade effect
	$('.fade-efect').each(function(){

		// build a scene
		var ourScene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook:.9,
			reverse:false
		})
		.setClassToggle(this, 'fade-in') // add class to project01
	//	.addIndicators({
	//		name: 'fade scene',
	//		colorTrigger: 'black',
	//		colorStart: '#75C695',
	//		colorEnd: 'pink'
	//	}) // this requires a plugin
		.addTo(controller);

	});
	
});
