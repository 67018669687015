jQuery('document').ready(function($) {
	var tip;
	var id;
	$(document).on('click', '.showModalButton', function(){
		//console.log($(this).attr('value'));
		//console.log($(this).data('tip'));
		//console.log($(this).data('id'));
		tip=$(this).data('tip');
		id=$(this).data('id');
		var value=$(this).attr('value');
		if (id !== undefined ) {
			value=value+"?id="+id;
		}
		$('#mmodal').find('#modal-title').empty();
		$('#mmodal').find('.modal-body').empty();
		if ($('#mmodal').data('bs.modal').isShown) {
			$('#mmodal').find('#modal-title').html($(this).attr('title'));
			$('#mmodal').find('.modal-body').load(value,function(){
				if(id!=4) {
					$('#mmodal').find("select#rezervareform-tip").remove();
					$('#mmodal').find("input[name='RezervareForm[tip]'").val(tip);
					$('#mmodal').find("input[name='RestaurantForm[tip]'").val(tip);
				} else {
					$('#mmodal').find("input#rezervareform-tip").remove();
				}
				
			});
	
		} else {
			$('#mmodal').find('#modal-title').html($(this).attr('title'));
			$('#mmodal').modal('show').find('.modal-body').load(value,function(){
				if(id!=4) {
					$('#mmodal').find("select#rezervareform-tip").remove();
					$('#mmodal').find("input[name='RezervareForm[tip]'").val(tip);
					$('#mmodal').find("input[name='RestaurantForm[tip]'").val(tip);
				} else {
					$('#mmodal').find("input#rezervareform-tip").remove();
				}
			});
			
		}
	});
	
	
	$('body').on('click', '#ltrimite', function(e) {
		//console.log('trimite');
		$('#frezervare').submit();
		
    });
	
	$('body').on('beforeSubmit', 'form#frezervare', function () {
		var form = $(this);
		if (form.find('.has-error').length) {
			return false;
		}
		// submit form
		$.ajax({
			url: form.attr('action'),
			type: 'post',
			data: form.serialize(),
		 	dataType: 'json',
			beforeSend: function() {
				$('.rotate').css('display','inline-block');
			},
			success: function (response) {
				$('#modal-title').empty();
				$('#modal-title').html(response['titlu']);
				$('#modrezervare').empty();
				$('#modrezervare').html(response['mesaj']);
				//console.log(response);
			},
			error:function(x,y,z) {
				console.log(x);
				console.log(y);
				console.log(z);
			},
		}).done(function() {
			$('.rotate').css('display','none');
			setTimeout(function(){
				$('#mmodal').modal('hide')
				//window.location.reload();
				}, 2000);
		});
		return false;
	});
	
	
	//Contact
	
	$('body').on('click', '#ctrimite', function(e) {
		//console.log('contact trimite');
		$('#fcontact').submit();
		
    });
	
	$('body').on('beforeSubmit', 'form#fcontact', function () {
		var form = $(this);
		if (form.find('.has-error').length) {
			return false;
		}
		// submit form
		$.ajax({
			url: form.attr('action'),
			type: 'post',
			data: form.serialize(),
		 	dataType: 'json',
			beforeSend: function() {
				$('.rotate').css('display','inline-block');
			},
			success: function (response) {
				$('#modal-title').empty();
				$('#modal-title').html(response['titlu']);
				$('#modcontact').empty();
				$('#modcontact').html(response['mesaj']);
				//console.log(response);
			},
			error:function(x,y,z) {
				console.log(x);
				console.log(y);
				console.log(z);
			},
		}).done(function() {
			$('.rotate').css('display','none');
			setTimeout(function(){
				$('#mmodal').modal('hide')
				//window.location.reload();
				}, 2000);
		});
		return false;
	});
	
	$(".showModalButton").on('click',function(){
		$("#wrap-header").css({'padding-right':'17px'});
	})
	
	$("#mmodal").on('shown.bs.modal', function () {
		//console.log('show');
		$("#wrap-header").css({'padding-right':'17px'});
		
		$("#lreset").on('click', function() {
			//console.log("reset");
			$("#frezervare").trigger("reset");
		
		})
		$("#creset").on('click', function() {
			//console.log("reset");
			$("#fcontact").trigger("reset");
		
		})
		
		
		
		
		// Calculator for  rezervation
		
		//console.log("form rezervare length "+$("#frezervare").length);
		if($("#frezervare").length >0) {
			if(id!=4) {
				$("#frezervare")[0].reset();
				//console.log("tip: "+tip);
				if(tip == 0) {
					AddAdulti(1);
				} else if(tip == 1) {
					AddAdulti(2);
				} else if(tip == 2) {
					AddAdulti(3);
				} else if(tip== 3) {
					AddAdulti(4);
				}
				$("#rezervareform-nr_adulti").on("change", function() {
					AddCopii(copii($("#rezervareform-tip").val(),this.value));
				})
	
				$("#rezervareform-nr_copii").on("change", function() {
					if($("#rezervareform-nr_adulti").val() == "") {
						AddAdulti(adulti($("#rezervareform-tip").val(),this.value));
					}
				})
			} 
			else (id==4) 
			{
				$("#frezervare .field-rezervareform-tip select").on("change", function() {
					ResetCopii();
					ResetAdulti();
					//console.log(this.value);
					if(this.value == 0) {
						AddAdulti(1);
					} else if(this.value == 1) {
						AddAdulti(2);
					} else if(this.value == 2) {
						AddAdulti(3);
					} else if(this.value == 3) {
						AddAdulti(4);
					}
					$("#frezervare .field-rezervareform-nr_adulti select").on("change", function() {
						AddCopii(copii($("#frezervare .field-rezervareform-tip select").val(),this.value));
					})
	
					$("#frezervare .field-rezervareform-nr_copii select").on("change", function() {
						if($("#frezervare .field-rezervareform-nr_adulti select").val() == "") {
							AddAdulti(adulti($("#frezervare .field-rezervareform-tip select").val(),this.value));
						}
					})
				})
			}
		}
		
	});

	$("#mmodal").on('hidden.bs.modal', function () {
		$("#wrap-header").css({'padding-right':'0'});
		
	});
	
	$('.modal-header .close').on('click',function(){
			$('.poveste_video').each(function(){
				this.pause();
			});
    });
	
	
	function adulti(tip,copii) {
			if (tip==0) { 
				return 1;
			} else if (tip==1 && copii==0) {
				return 2;
			} else if(tip==1 && copii==1) {
				return 2;
			} else if(tip==1 && copii==2) {
				return 1;
			}  else if (tip==2 && copii==0) {
				return 3;	
			} else if(tip==2 && copii==1) {
				return 3;
			} else if(tip==2 && copii==2) {
				return 2;
			} else if (tip==2 && copii==3) {
				return 1;
			} else if (tip==3 && copii==0) {
				return 4;	
			} else if (tip==3 && copii==1) {
			   return 4;
			} else if (tip==3 && copii==2) {
			   return 3;
			} else if (tip==3 && copii==3) {
				return 2;
			} else if(tip==3 && copii==4) {
				return 1;
			} else {
				return 0;
			}
		}
			
		function copii(tip,adulti) {
			if (tip==0) { 
				return 1;
			} else if (tip==1 && adulti == 1) {
				return 2;
			} else if(tip==1 && adulti ==2) {
				return 1;
			}  else if (tip==2 && adulti==1) {
				return 3;	
			} else if(tip==2 && adulti==2) {
				return 2;
			} else if(tip==2 && adulti==3) {
				return 1;
			} else if(tip==3 && adulti==1) {
				return 4;
			} else if (tip==3 && adulti==2) {
				return 3;
			} else if (tip==3 && adulti==3) {
				return 2;	
			} else if (tip==3 && adulti==4) {
				return 1;
			} else {
				return 0;	
			}
			
		}
	
		function ResetAdulti() {
			if(id==4) {
				$("#frezervare .field-rezervareform-nr_adulti select")
				.find('option')
				.remove()
				.end();
				$("#frezervare .field-rezervareform-nr_adulti select").append('<option value="" selected="selected">Nr Adulti</option>');
				
			}else{
				$("#rezervareform-nr_adulti")
				.find('option')
				.remove()
				.end();
				$("#rezervareform-nr_adulti").append('<option value="" selected="selected">Nr Adulti</option>');
			}	
		}
	
		function ResetCopii() {
			if(id==4) {
				$("#frezervare .field-rezervareform-nr_copii select")
				.find('option')
				.remove()
				.end();
				$("#frezervare .field-rezervareform-nr_copii select").append('<option value="" selected="selected">Nr Copii (0 - 3 ani)</option>');
				
			} else {
				$("#rezervareform-nr_copii")
				.find('option')
				.remove()
				.end();
				$("#rezervareform-nr_copii").append('<option value="" selected="selected">Nr Copii (0 - 3 ani)</option>');
			}
		}
	
		function AddAdulti(i) {
			//console.log("Adulti i:"+i);
			ResetAdulti();
			for(let j=1;j<=i;j++) {
				if(id==4) {
				$("#frezervare .field-rezervareform-nr_adulti select")
				.append('<option value="'+j+'">'+j+'</option>');
				}else {
				$("#rezervareform-nr_adulti")
				.append('<option value="'+j+'">'+j+'</option>');
				}
			}
		}
	
		function AddCopii(i) {
			ResetCopii();
			//console.log("copii i:"+i);
			for(let j=0;j<=i;j++) {
				//console.log("copii j:"+j);
				if(id==4) {
				$("#frezervare .field-rezervareform-nr_copii select")
				.append('<option value="'+j+'">'+j+'</option>');
				}else{
				$("#rezervareform-nr_copii")
				.append('<option value="'+j+'">'+j+'</option>');
				}
			}
		}
	
});