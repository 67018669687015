/* Gallery Isotope */
    function GalleryIsotope() {
        if ($('.gallery').length) {
			//console.log('gallery');
            $('.gallery').each(function (index, el) {
                var $this = $(this),
                    $isotope = $this.find('.gallery-isotope'),
                    $filter = $this.find('.gallery-cat');
                    //console.log($isotope);
					//console.log($filter);
                if ($isotope.length) {
                    var isotope_run = function (filter) {
                        $isotope.isotope({
                            itemSelector: '.item-isotope',
                            filter: filter,
                            percentPosition: true,
                            masonry: {columnWidth: '.item-size'},
                            transitionDuration: '0.8s',
                            hiddenStyle: {opacity: 0},
                            visibleStyle: {opacity: 1}
                        });
						//console.log($isotope);
                    }

                    $filter.on('click', 'a', function (event) {
						//console.log("click");
                        event.preventDefault();
                        $(this).parents('ul').find('.active').removeClass('active');
                        $(this).parent('li').addClass('active');
                        isotope_run($(this).attr('data-filter'));
                    });

                    isotope_run('*');
                }
            });
        }
    }
	$(document).ready(function () {
     //  $(window).on('load',function () {
			GalleryIsotope();
	  // })
	})
		